import { render, staticRenderFns } from "./iconTrash.vue?vue&type=template&id=33db12f4&scoped=true"
import script from "./iconTrash.vue?vue&type=script&lang=js"
export * from "./iconTrash.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33db12f4",
  null
  
)

export default component.exports