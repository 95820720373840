<template>
  <icon-base :width="size + 'px'" :fill="color" viewBox="0 0 250 250" class="icon-trash">
      <g>
        <path d="M165.6,25h-34.4v-6.2c0-10.4-8.4-18.7-18.7-18.7h-25C77.2,0,68.8,8.4,68.8,18.8V25H34.4   
        c-8.6,0-15.6,7-15.6,15.6v12.5c0,3.5,2.8,6.2,6.2,6.2h150c3.5,0,6.2-2.8,6.2-6.2V40.6C181.2,32,174.2,25,165.6,25z M118.7,25H81.3   
        v-6.2c0-3.4,2.8-6.2,6.2-6.2h25c3.4,0,6.2,2.8,6.2,6.2V25z">
        </path>
        <path 
        d="M169.4,71.9H30.6c-1.1,0-2,0.9-1.9,2l5.2,108.2c0.5,10,8.7,17.9,18.7,17.9h94.9c10,0,18.2-7.8,18.7-17.9   
        l5.2-108.2C171.4,72.8,170.5,71.9,169.4,71.9z M75,168.7c0,3.5-2.8,6.2-6.2,6.2c-3.5,0-6.2-2.8-6.2-6.2V87.5c0-3.5,2.8-6.2,6.2-6.2   
        c3.5,0,6.2,2.8,6.2,6.2V168.7z M106.2,168.7c0,3.5-2.8,6.2-6.2,6.2s-6.2-2.8-6.2-6.2V87.5c0-3.5,2.8-6.2,6.2-6.2s6.2,2.8,6.2,6.2   
        V168.7z M137.5,168.7c0,3.5-2.8,6.2-6.2,6.2c-3.5,0-6.2-2.8-6.2-6.2V87.5c0-3.5,2.8-6.2,6.2-6.2c3.5,0,6.2,2.8,6.2,6.2V168.7z">
        </path>
      </g>
  </icon-base>
</template>

<script>
export default {
  name: 'iconTrash',
  components: {
    iconBase: () => import('@/common/components/iconBase.vue')
  },
  props: {
    size: {
      type: Number,
      default: 20
    },
    color: {
      type: String,
      default: 'var(--light-main-navy)'
    },
    enableBackground: {
      type: Boolean
    }
  }
}
</script>

<style scoped>

</style>
